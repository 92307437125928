import { Moment } from './moment';

export type Sex = 'M' | 'F' | 'U';

export class Person {
  firstname = '';
  nickname = '';
  prefix = '';
  lastname = '';

  sex: Sex = 'U';

  birth?: Moment;
  death?: Moment;

  occupation: string[] = [];

  parents?: string;

  get name(): string {
    return [this.nickname ?? this.firstname, this.prefix, this.lastname]
      .filter(x => x)
      .join(' ');
  }

  age(date: Date = this.death?.date ?? new Date()): number | undefined {
    return this.birth?.date && date
      ? Math.floor(
          (date.getTime() - this.birth.date.getTime()) / 1000 / 3600 / 24 / 365,
        )
      : undefined;
  }
}
