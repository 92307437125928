import { Moment } from './moment';
import { Person } from './person';

export class Family {
  man?: Person;
  woman?: Person;
  children: Person[] = [];
  marriage?: Moment;

  get name(): string {
    return (
      [this.man, this.woman]
        .filter(p => p)
        .map(p => p!.name)
        .join(' & ') +
      (this.marriage?.date ? ` (${this.marriage.date.getFullYear()})` : '')
    );
  }
}
