<div class="settings">
  <div class="body title">Visualize family tree</div>

  <div class="body">
    <div class="form-group">
      <label for="file">GEDCOM file</label>
      <input
        type="file"
        name="file"
        id="file"
        (change)="upload($any($event.target).files)"
        accept=".ged"
      />
    </div>
  </div>

  <form [formGroup]="form" (submit)="render()">
    <div class="body">
      <div class="spread">
        <div
          class="form-group"
          [style.min-width.%]="75"
          [style.max-width.%]="75"
        >
          <label for="familyId">Family</label>
          <ng-select
            id="familyId"
            name="familyId"
            [items]="families() | keyvalue"
            formControlName="familyId"
            bindValue="key"
            bindLabel="value.name"
            [virtualScroll]="true"
            [clearable]="false"
          >
          </ng-select>
        </div>

        <div class="form-group">
          <label for="nGenerations">Generations</label>
          <input
            id="nGenerations"
            name="nGenerations"
            type="number"
            formControlName="nGenerations"
          />
        </div>
      </div>

      <div class="spread">
        <div class="form-group">
          <label for="startYear">Start year</label>
          <input
            id="startYear"
            name="startYear"
            type="number"
            formControlName="startYear"
          />
        </div>

        <div class="form-group">
          <label for="endYear">End year</label>
          <input
            id="endYear"
            name="endYear"
            type="number"
            formControlName="endYear"
          />
        </div>
      </div>

      <ng-container formGroupName="sizes">
        <div class="spread">
          <div class="form-group">
            <label for="width">Width (mm)</label>
            <input
              id="width"
              name="width"
              type="number"
              formControlName="width"
            />
          </div>

          <div class="form-group">
            <label for="height">Height (mm)</label>
            <input
              id="height"
              name="height"
              type="number"
              formControlName="height"
            />
          </div>
        </div>

        <div class="spread">
          <div class="form-group">
            <label for="margin">Margin (mm)</label>
            <input
              id="margin"
              name="margin"
              type="number"
              formControlName="margin"
            />
          </div>

          <div class="form-group">
            <label for="bleed">Bleed (mm)</label>
            <input
              id="bleed"
              name="bleed"
              type="number"
              formControlName="bleed"
            />
          </div>
        </div>
      </ng-container>

      <div class="spread">
        <div class="form-group">
          <label for="showAges">Show ages</label>
          <ng-select
            id="showAges"
            name="showAges"
            [items]="booleans"
            formControlName="showAges"
            bindValue="value"
            bindLabel="label"
            [clearable]="false"
          >
          </ng-select>
        </div>

        <div class="form-group">
          <label for="showChildren">Show children</label>
          <ng-select
            id="showChildren"
            name="showChildren"
            [items]="booleans"
            formControlName="showChildren"
            bindValue="value"
            bindLabel="label"
            [clearable]="false"
          >
          </ng-select>
        </div>

        <div class="form-group">
          <label for="showOccupations">Show occupations</label>
          <ng-select
            id="showOccupations"
            name="showOccupations"
            [items]="booleans"
            formControlName="showOccupations"
            bindValue="value"
            bindLabel="label"
            [clearable]="false"
          >
          </ng-select>
        </div>

        <div class="form-group">
          <label for="language">Language</label>
          <ng-select
            id="language"
            name="language"
            [items]="languages"
            formControlName="language"
            bindValue="value"
            bindLabel="label"
            [clearable]="false"
          >
          </ng-select>
        </div>
      </div>
    </div>

    <div class="body">
      <button type="submit" [disabled]="form.pristine || form.invalid">
        Render
      </button>
    </div>
  </form>
</div>
