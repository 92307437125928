export class Moment {
  date?: Date;
  unsure?: boolean;

  place?: string;

  dateString(locale: string): string {
    return this.date
      ? this.date.toLocaleString(locale, {
          ...(this.unsure ? {} : { day: 'numeric', month: 'long' }),
          year: 'numeric',
        })
      : '';
  }
}
