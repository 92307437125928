import { KeyValuePipe } from '@angular/common';
import { Component, output, signal } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Family, Settings } from '@app/classes';
import { parseGedcom, readFile } from '@app/functions';
import { NgSelectModule } from '@ng-select/ng-select';
import { combineLatest, filter } from 'rxjs';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  standalone: true,
  imports: [ReactiveFormsModule, NgSelectModule, KeyValuePipe],
})
export class SettingsComponent {
  readonly done = output<Settings>();

  readonly booleans = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ];

  readonly languages = [
    { value: 'en-US', label: 'English' },
    { value: 'nl-NL', label: 'Dutch' },
  ];

  readonly form = new FormGroup({
    familyId: new FormControl<string | undefined>(undefined, {
      validators: [Validators.required],
      nonNullable: true,
    }),
    nGenerations: new FormControl<number | undefined>(undefined, {
      validators: [Validators.required],
      nonNullable: true,
    }),

    startYear: new FormControl<number | undefined>(undefined, {
      validators: [Validators.required],
      nonNullable: true,
    }),
    endYear: new FormControl<number | undefined>(undefined, {
      validators: [Validators.required],
      nonNullable: true,
    }),

    sizes: new FormGroup({
      width: new FormControl<number>(841, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      height: new FormControl<number>(594, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      margin: new FormControl<number>(12.7, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      bleed: new FormControl<number>(3, {
        validators: [Validators.required],
        nonNullable: true,
      }),
    }),

    showAges: new FormControl<boolean>(true, {
      validators: [Validators.required],
      nonNullable: true,
    }),
    showChildren: new FormControl<boolean>(true, {
      validators: [Validators.required],
      nonNullable: true,
    }),
    showOccupations: new FormControl<boolean>(true, {
      validators: [Validators.required],
      nonNullable: true,
    }),

    language: new FormControl<string>('nl-NL', {
      validators: [Validators.required],
      nonNullable: true,
    }),

    generations: new FormControl<Family[][]>([], {
      validators: [Validators.required],
      nonNullable: true,
    }),
  });

  readonly families = signal<Record<string, Family>>({});

  constructor() {
    this.form.disable();

    combineLatest({
      familyId: this.form.controls.familyId.valueChanges.pipe(filter(Boolean)),
      nGenerations: this.form.controls.nGenerations.valueChanges.pipe(
        filter(Boolean),
      ),
    }).subscribe(({ familyId, nGenerations }) => {
      const family = this.families()[familyId];

      const generations: Family[][] = [[family]];

      for (let i = 0; i < (nGenerations ?? 0) - 1; i++) {
        const entities: Family[] = [];

        for (const e of generations[i]) {
          if (e.man?.parents) {
            entities.push(this.families()[e.man.parents]);
          }

          if (e.woman?.parents) {
            entities.push(this.families()[e.woman.parents]);
          }
        }

        generations.push(entities);
      }

      let year = new Date().getFullYear();
      for (const generation of generations) {
        for (const fam of generation) {
          if (fam.man?.birth?.date) {
            year = Math.min(year, fam.man.birth.date.getFullYear());
          }
          if (fam.woman?.birth?.date) {
            year = Math.min(year, fam.woman.birth.date.getFullYear());
          }
        }
      }

      const startYear = Math.floor(year / 10) * 10;
      const endYear =
        Math.ceil(
          (generations[0][0].marriage?.date || new Date()).getFullYear() / 10,
        ) * 10;

      this.form.controls.startYear.patchValue(startYear);
      this.form.controls.endYear.patchValue(endYear);
      this.form.controls.generations.patchValue(generations);
    });
  }

  upload(files: FileList): void {
    readFile(files.item(0)!)
      .then(gedcom => {
        this.form.controls.familyId.patchValue(undefined);
        this.form.controls.nGenerations.patchValue(5);
        this.form.controls.startYear.patchValue(undefined);
        this.form.controls.endYear.patchValue(undefined);

        this.families.set(parseGedcom(gedcom));
        this.form.enable();
      })
      .catch(() => {
        alert("That's not a GEDCOM file!");
      });
  }

  render(): void {
    if (this.form.valid) {
      this.done.emit(this.form.value as Settings);
    }
  }
}
