import {
  Component,
  ElementRef,
  Renderer2,
  computed,
  effect,
  inject,
  signal,
  viewChild,
} from '@angular/core';
import { Settings } from '@app/classes';
import { SettingsComponent } from './settings/settings.component';

const RATIO = 3.77952755905;

const NS = 'http://www.w3.org/2000/svg';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [SettingsComponent],
})
export class AppComponent {
  readonly r2 = inject(Renderer2);
  readonly svg = viewChild.required<ElementRef<SVGElement>>('svg');

  readonly RATIO = RATIO;

  readonly setting = signal(true);

  readonly settings = signal<Settings | undefined>(undefined);

  readonly width = computed(() => this.settings()?.sizes.width ?? 841);
  readonly height = computed(() => this.settings()?.sizes.height ?? 594);

  readonly viewBox = computed(() => {
    const settings = this.settings();

    return settings
      ? [
          -(settings.sizes.bleed ?? 0),
          -(settings.sizes.bleed ?? 0),
          this.width() + (settings.sizes.bleed ?? 0),
          this.height() + (settings.sizes.bleed ?? 0),
        ]
          .map(x => x * RATIO)
          .join(' ')
      : '0 0 0 0';
  });

  private group?: SVGGElement;

  constructor() {
    effect(() => {
      const svg = this.svg().nativeElement;
      const settings = this.settings();

      if (svg && settings) {
        if (this.group) {
          this.r2.removeChild(svg, this.group);
        }

        const {
          startYear,
          endYear,
          sizes,
          showAges,
          showChildren,
          showOccupations,
          generations,
          language,
        } = settings;

        const px = (date?: Date): string =>
          `${
            (sizes.margin +
              (((date
                ? date.getTime() / 1000 / 3600 / 24 / 365 + 1970
                : startYear) -
                startYear) *
                (sizes.height - 2 * sizes.margin)) /
                (endYear - startYear)) *
            RATIO
          }`;

        this.group = this.r2.createElement('g', NS) as SVGGElement;

        // Time lines
        const timeGroup = this.r2.createElement('g', NS) as SVGGElement;
        for (let year = startYear; year <= endYear; year += 10) {
          const d = new Date(`1-1-${year}`);

          const group = this.r2.createElement('g', NS) as SVGGElement;
          this.r2.setAttribute(group, 'transform', `translate(0,${px(d)})`);

          {
            const line = this.r2.createElement('line', NS) as SVGLineElement;

            this.r2.setAttribute(line, 'x1', `${-sizes.bleed * 10 * RATIO}`);
            this.r2.setAttribute(line, 'y1', '0');
            this.r2.setAttribute(
              line,
              'x2',
              `${(sizes.width + sizes.bleed * 10) * RATIO}`,
            );
            this.r2.setAttribute(line, 'y2', '0');
            this.r2.addClass(line, 'decennium');
            if (!(year % 100)) this.r2.addClass(line, 'century');

            this.r2.appendChild(group, line);
          }

          {
            const text = this.r2.createElement('text', NS) as SVGLineElement;

            this.r2.setAttribute(text, 'x', `${sizes.margin * RATIO}`);
            this.r2.setAttribute(text, 'y', '-5');
            this.r2.setAttribute(text, 'text-anchor', 'start');
            this.r2.addClass(text, 'decennium-text');

            this.r2.appendChild(text, this.r2.createText(`${year}`));

            this.r2.appendChild(group, text);
          }

          this.r2.appendChild(timeGroup, group);
        }
        this.r2.appendChild(this.group, timeGroup);

        for (let i = 0; i < generations.length; i++) {
          const distance = ((sizes.width - 2 * sizes.margin) * RATIO) / 2 ** i;

          for (let j = 0; j < generations[i].length; j++) {
            const family = generations[i][j];

            const x = sizes.margin * RATIO + distance / 2 + distance * j;

            const familyGroup = this.r2.createElement('g', NS) as SVGGElement;
            this.r2.setAttribute(familyGroup, 'transform', `translate(${x},0)`);

            // Child dots
            if (showChildren) {
              for (const child of family.children) {
                if (child.birth?.date) {
                  const twins = family.children.filter(
                    c =>
                      c.birth?.date &&
                      Math.abs(
                        child.birth!.date!.getTime() - c.birth?.date.getTime(),
                      ) <
                        10 * 24 * 3600,
                  );
                  const index = twins.indexOf(child);
                  const width = (4 - (twins.length - 1) * 0.5) / twins.length;
                  const offset = index * (width + 0.5);

                  const age = child.age();

                  const line = this.r2.createElement(
                    'line',
                    NS,
                  ) as SVGLineElement;

                  this.r2.setAttribute(line, 'x1', `${-2 + offset}`);
                  this.r2.setAttribute(line, 'y1', px(child.birth?.date));
                  this.r2.setAttribute(line, 'x2', `${-2 + offset + width}`);
                  this.r2.setAttribute(line, 'y2', px(child.birth?.date));
                  this.r2.addClass(line, 'lifeline');
                  this.r2.addClass(line, child.sex === 'M' ? 'man' : 'woman');
                  if (age && age < 18) this.r2.addClass(line, 'deceased');
                  this.r2.setStyle(
                    line,
                    'opacity',
                    `${0.7 ** Math.abs(i - 1)}`,
                  );

                  this.r2.appendChild(familyGroup, line);
                } else {
                  console.error('Missing birth', child);
                }
              }
            }

            // Man life line
            if (family.man) {
              const line = this.r2.createElement('line', NS) as SVGLineElement;

              this.r2.setAttribute(line, 'x1', '-4');
              this.r2.setAttribute(line, 'y1', px(family.man.birth?.date));
              this.r2.setAttribute(line, 'x2', '-4');
              this.r2.setAttribute(
                line,
                'y2',
                px(family.man.death?.date || new Date()),
              );
              this.r2.addClass(line, 'lifeline');
              this.r2.addClass(line, 'man');
              this.r2.setStyle(line, 'opacity', `${0.7 ** i}`);

              this.r2.appendChild(familyGroup, line);
            }

            // Woman life line
            if (family.woman) {
              const line = this.r2.createElement('line', NS) as SVGLineElement;

              this.r2.setAttribute(line, 'x1', '4');
              this.r2.setAttribute(line, 'y1', px(family.woman.birth?.date));
              this.r2.setAttribute(line, 'x2', '4');
              this.r2.setAttribute(
                line,
                'y2',
                px(family.woman.death?.date || new Date()),
              );
              this.r2.addClass(line, 'lifeline');
              this.r2.addClass(line, 'woman');
              this.r2.setStyle(line, 'opacity', `${0.7 ** i}`);

              this.r2.appendChild(familyGroup, line);
            }

            {
              const y = px(
                family.marriage?.date || family.children[0]?.birth?.date,
              );

              const marriageGroup = this.r2.createElement(
                'g',
                NS,
              ) as SVGGElement;
              this.r2.setAttribute(
                marriageGroup,
                'transform',
                `translate(0,${y})`,
              );

              // Marriage line
              if (family.marriage) {
                const line = this.r2.createElement(
                  'line',
                  NS,
                ) as SVGLineElement;

                this.r2.setAttribute(line, 'x1', '-73');
                this.r2.setAttribute(line, 'y1', '0');
                this.r2.setAttribute(line, 'x2', '73');
                this.r2.setAttribute(line, 'y2', '0');
                this.r2.addClass(line, 'marriage');

                this.r2.appendChild(marriageGroup, line);
              }

              // Marriage place
              if (family.marriage) {
                const text = this.r2.createElement(
                  'text',
                  NS,
                ) as SVGTextElement;

                this.r2.setAttribute(text, 'x', '0');
                this.r2.setAttribute(text, 'y', '-10');
                this.r2.setAttribute(text, 'text-anchor', 'middle');
                this.r2.addClass(text, 'text-marriage');

                this.r2.appendChild(
                  text,
                  this.r2.createText(family.marriage.place || ''),
                );

                this.r2.appendChild(marriageGroup, text);
              }

              // Marriage date
              if (family.marriage) {
                const text = this.r2.createElement(
                  'text',
                  NS,
                ) as SVGTextElement;

                this.r2.setAttribute(text, 'x', '0');
                this.r2.setAttribute(text, 'y', '-30');
                this.r2.setAttribute(text, 'text-anchor', 'middle');

                this.r2.appendChild(
                  text,
                  this.r2.createText(family.marriage.dateString(language)),
                );

                this.r2.appendChild(marriageGroup, text);
              }

              // Man marriage age
              if (showAges && family.man) {
                const text = this.r2.createElement(
                  'text',
                  NS,
                ) as SVGTextElement;

                this.r2.setAttribute(text, 'x', '-80');
                this.r2.setAttribute(text, 'y', '5');
                this.r2.setAttribute(text, 'text-anchor', 'end');

                this.r2.appendChild(
                  text,
                  this.r2.createText(
                    `${family.man.age(family.marriage?.date) || ''}`,
                  ),
                );

                this.r2.appendChild(marriageGroup, text);
              }

              // Woman marriage age
              if (showAges && family.woman) {
                const text = this.r2.createElement(
                  'text',
                  NS,
                ) as SVGTextElement;

                this.r2.setAttribute(text, 'x', '80');
                this.r2.setAttribute(text, 'y', '5');
                this.r2.setAttribute(text, 'text-anchor', 'start');

                this.r2.appendChild(
                  text,
                  this.r2.createText(
                    `${family.woman.age(family.marriage?.date) || ''}`,
                  ),
                );

                this.r2.appendChild(marriageGroup, text);
              }

              // Man first name
              if (family.man) {
                const text = this.r2.createElement(
                  'text',
                  NS,
                ) as SVGTextElement;

                this.r2.setAttribute(text, 'x', '-18');
                this.r2.setAttribute(text, 'y', '28');
                this.r2.setAttribute(text, 'text-anchor', 'end');
                this.r2.addClass(text, 'big');

                this.r2.appendChild(
                  text,
                  this.r2.createText(family.man.firstname),
                );

                this.r2.appendChild(marriageGroup, text);
              }

              // Man last name
              if (family.man) {
                const text = this.r2.createElement(
                  'text',
                  NS,
                ) as SVGTextElement;

                this.r2.setAttribute(text, 'x', '-18');
                this.r2.setAttribute(text, 'y', '48');
                this.r2.setAttribute(text, 'text-anchor', 'end');
                this.r2.addClass(text, 'big');

                this.r2.appendChild(
                  text,
                  this.r2.createText(
                    [family.man.prefix, family.man.lastname]
                      .filter(x => x)
                      .join(' '),
                  ),
                );

                this.r2.appendChild(marriageGroup, text);
              }

              // Woman first name
              if (family.woman) {
                const text = this.r2.createElement(
                  'text',
                  NS,
                ) as SVGTextElement;

                this.r2.setAttribute(text, 'x', '18');
                this.r2.setAttribute(text, 'y', '28');
                this.r2.setAttribute(text, 'text-anchor', 'start');
                this.r2.addClass(text, 'big');

                this.r2.appendChild(
                  text,
                  this.r2.createText(family.woman.firstname),
                );

                this.r2.appendChild(marriageGroup, text);
              }

              // Woman last name
              if (family.woman) {
                const text = this.r2.createElement(
                  'text',
                  NS,
                ) as SVGTextElement;

                this.r2.setAttribute(text, 'x', '18');
                this.r2.setAttribute(text, 'y', '48');
                this.r2.setAttribute(text, 'text-anchor', 'start');
                this.r2.addClass(text, 'big');

                this.r2.appendChild(
                  text,
                  this.r2.createText(
                    [family.woman.prefix, family.woman.lastname]
                      .filter(x => x)
                      .join(' '),
                  ),
                );

                this.r2.appendChild(marriageGroup, text);
              }

              if (!showOccupations) {
                if (family.man) {
                  family.man.occupation = [];
                }

                if (family.woman) {
                  family.woman.occupation = [];
                }
              }

              if (family.man) {
                // Man occupation
                for (let i = 0; i < family.man?.occupation.length; i++) {
                  const text = this.r2.createElement(
                    'text',
                    NS,
                  ) as SVGTextElement;

                  this.r2.setAttribute(text, 'x', '-18');
                  this.r2.setAttribute(text, 'y', `${68 + i * 18}`);
                  this.r2.setAttribute(text, 'text-anchor', 'end');
                  this.r2.addClass(text, 'occupation');

                  this.r2.appendChild(
                    text,
                    this.r2.createText(family.man.occupation[i].toLowerCase()),
                  );

                  this.r2.appendChild(marriageGroup, text);
                }

                // Man birth date
                {
                  const text = this.r2.createElement(
                    'text',
                    NS,
                  ) as SVGTextElement;

                  this.r2.setAttribute(
                    text,
                    'x',
                    `${60 + family.man.occupation.length * 18}`,
                  );
                  this.r2.setAttribute(text, 'y', '28');
                  this.r2.setAttribute(text, 'text-anchor', 'start');
                  this.r2.setAttribute(text, 'transform', 'rotate(90)');

                  this.r2.appendChild(
                    text,
                    this.r2.createText(
                      [
                        family.man.birth?.dateString(language),
                        family.man.birth?.place,
                      ]
                        .filter(x => x)
                        .join(' - '),
                    ),
                  );

                  this.r2.appendChild(marriageGroup, text);
                }

                // Man death date
                {
                  const text = this.r2.createElement(
                    'text',
                    NS,
                  ) as SVGTextElement;

                  this.r2.setAttribute(
                    text,
                    'x',
                    `${60 + family.man.occupation.length * 18}`,
                  );
                  this.r2.setAttribute(text, 'y', '48');
                  this.r2.setAttribute(text, 'text-anchor', 'start');
                  this.r2.setAttribute(text, 'transform', 'rotate(90)');
                  this.r2.addClass(text, 'gray');

                  this.r2.appendChild(
                    text,
                    this.r2.createText(
                      [
                        family.man.death?.dateString(language),
                        family.man.death?.place,
                      ]
                        .filter(x => x)
                        .join(' - '),
                    ),
                  );

                  this.r2.appendChild(marriageGroup, text);
                }
              }

              if (family.woman) {
                // Woman occupation
                for (let i = 0; i < family.woman?.occupation.length; i++) {
                  const text = this.r2.createElement(
                    'text',
                    NS,
                  ) as SVGTextElement;

                  this.r2.setAttribute(text, 'x', '18');
                  this.r2.setAttribute(text, 'y', `${68 + i * 18}`);
                  this.r2.setAttribute(text, 'text-anchor', 'start');
                  this.r2.addClass(text, 'occupation');

                  this.r2.appendChild(
                    text,
                    this.r2.createText(
                      family.woman.occupation[i].toLowerCase(),
                    ),
                  );

                  this.r2.appendChild(marriageGroup, text);
                }

                // Woman birth date
                {
                  const text = this.r2.createElement(
                    'text',
                    NS,
                  ) as SVGTextElement;

                  this.r2.setAttribute(
                    text,
                    'x',
                    `${-60 - family.woman.occupation.length * 18}`,
                  );
                  this.r2.setAttribute(text, 'y', '28');
                  this.r2.setAttribute(text, 'text-anchor', 'end');
                  this.r2.setAttribute(text, 'transform', 'rotate(-90)');

                  this.r2.appendChild(
                    text,
                    this.r2.createText(
                      [
                        family.woman.birth?.dateString(language),
                        family.woman.birth?.place,
                      ]
                        .filter(x => x)
                        .join(' - '),
                    ),
                  );

                  this.r2.appendChild(marriageGroup, text);
                }

                // Woman death date
                {
                  const text = this.r2.createElement(
                    'text',
                    NS,
                  ) as SVGTextElement;

                  this.r2.setAttribute(
                    text,
                    'x',
                    `${-60 - family.woman.occupation.length * 18}`,
                  );
                  this.r2.setAttribute(text, 'y', '48');
                  this.r2.setAttribute(text, 'text-anchor', 'end');
                  this.r2.setAttribute(text, 'transform', 'rotate(-90)');
                  this.r2.addClass(text, 'gray');

                  this.r2.appendChild(
                    text,
                    this.r2.createText(
                      [
                        family.woman.death?.dateString(language),
                        family.woman.death?.place,
                      ]
                        .filter(x => x)
                        .join(' - '),
                    ),
                  );

                  this.r2.appendChild(marriageGroup, text);
                }
              }

              // Man death age
              if (showAges && family.man) {
                const text = this.r2.createElement(
                  'text',
                  NS,
                ) as SVGTextElement;

                this.r2.setAttribute(text, 'x', '-58');
                this.r2.setAttribute(
                  text,
                  'y',
                  `${70 + family.man.occupation.length * 18}`,
                );
                this.r2.setAttribute(text, 'text-anchor', 'end');
                this.r2.addClass(text, 'gray');

                this.r2.appendChild(
                  text,
                  this.r2.createText(`${family.man.age() || ''}`),
                );

                this.r2.appendChild(marriageGroup, text);
              }

              // Woman death age
              if (showAges && family.woman) {
                const text = this.r2.createElement(
                  'text',
                  NS,
                ) as SVGTextElement;

                this.r2.setAttribute(text, 'x', '58');
                this.r2.setAttribute(
                  text,
                  'y',
                  `${70 + family.woman.occupation.length * 18}`,
                );
                this.r2.setAttribute(text, 'text-anchor', 'start');
                this.r2.addClass(text, 'gray');

                this.r2.appendChild(
                  text,
                  this.r2.createText(`${family.woman.age() || ''}`),
                );

                this.r2.appendChild(marriageGroup, text);
              }

              this.r2.appendChild(familyGroup, marriageGroup);
            }

            this.r2.appendChild(this.group, familyGroup);
          }
        }

        this.r2.appendChild(svg, this.group);
      }
    });
  }

  download(): void {
    if (!this.svg) {
      return;
    }

    const linkElement = document.createElement('a');
    const href =
      'data:image/svg+xml;charset=utf-8,<?xml version="1.0" encoding="UTF-8" standalone="no"?>' +
      encodeURIComponent(this.svg().nativeElement.outerHTML);

    linkElement.setAttribute('href', href);
    linkElement.setAttribute('download', 'family-tree.svg');

    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false,
    });
    linkElement.dispatchEvent(clickEvent);
  }
}
