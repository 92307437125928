import { Family, Moment, Person, Sex } from '../classes';

export function parseGedcom(gedcom: string): Record<string, Family> {
  const all = gedcom.split('\r').join('').split('\n0 ');

  const people: Record<string, Person> = {};
  const families: Record<string, Family> = {};

  const familyRaws: Record<string, string> = {};

  for (const lines of all) {
    const [id, key] = lines.split('\n')[0].split(' ');

    switch (key) {
      case 'INDI':
        try {
          const r = new Person();

          for (const line of lines.split('\n1 ')) {
            const [key, data] = line.split('\n')[0].split(' ');

            switch (key) {
              case 'NAME':
                for (const l of line.split('\n2 ')) {
                  const [k, ...d] = l.split('\n')[0].split(' ');

                  switch (k) {
                    case 'GIVN':
                      r.firstname = d.join(' ');
                      break;

                    case 'NICK':
                      r.nickname = d.join(' ');
                      break;

                    case 'SPFX':
                      r.prefix = d.join(' ');
                      break;

                    case 'SURN':
                      r.lastname = d.join(' ');
                      break;
                  }
                }
                break;

              case 'SEX':
                r.sex = data as Sex;
                break;

              case 'OCCU':
                r.occupation.push(data);
                break;

              case 'FAMC':
                r.parents = data;
                break;

              case 'BIRT':
              case 'BAPM':
                if (key === 'BAPM' && r.birth) break;

                r.birth = new Moment();

                for (const l of line.split('\n2 ')) {
                  const [k, ...d] = l.split('\n')[0].split(' ');

                  switch (k) {
                    case 'DATE':
                      r.birth.date = new Date(d.join(' '));
                      r.birth.unsure = d[0] === 'ABT';
                      break;

                    case 'PLAC':
                      r.birth.place = d.join(' ').split(',')[0];
                      break;
                  }
                }
                break;

              case 'DEAT':
                r.death = new Moment();

                for (const l of line.split('\n2 ')) {
                  const [k, ...d] = l.split('\n')[0].split(' ');

                  switch (k) {
                    case 'DATE':
                      r.death.date = new Date(d.join(' '));
                      r.death.unsure = d[0] === 'ABT';
                      break;

                    case 'PLAC':
                      r.death.place = d.join(' ').split(',')[0];
                      break;
                  }
                }
                break;
            }
          }

          people[id] = r;
        } catch {}
        break;

      case 'FAM':
        familyRaws[id] = lines;
        break;
    }
  }

  for (const id in familyRaws) {
    try {
      const lines = familyRaws[id].split('\n1 ');

      const r = new Family();
      for (const line of lines) {
        const [key, data] = line.split('\n')[0].split(' ');

        switch (key) {
          case 'HUSB':
            r.man = people[data];
            break;

          case 'WIFE':
            r.woman = people[data];
            break;

          case 'CHIL':
            r.children.push(people[data]);
            break;

          case 'MARR':
            r.marriage = new Moment();

            for (const l of line.split('\n2 ')) {
              const [k, ...d] = l.split('\n')[0].split(' ');

              switch (k) {
                case 'DATE':
                  r.marriage.date = new Date(d.join(' '));
                  r.marriage.unsure = d[0] === 'ABT';
                  break;

                case 'PLAC':
                  r.marriage.place = d.join(' ').split(',')[0];
                  break;
              }
            }
            break;
        }
      }

      families[id] = r;
    } catch {}
  }

  return families;
}
